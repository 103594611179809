@import url('https://fonts.googleapis.com/css?family=Notable');

  .navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    margin-bottom: 20px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
        position: absolute;
        width: 99px;
        height: 75px;
        background: #676767;
        font-family: 'Holtwood One SC';
        text-decoration: none;
  }

  .navbar-logo p {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #EEEEEE;
  }
  
  .nav-menu {
    width: 55%;
    float: right;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-links:hover {
    color: gray;
  }
  
  .nav-links {
    font-family: "Notable";
    list-style-type: none;
    display: inline;
    text-decoration: underline;
    font-size: 12px;
    color: black;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .navbar {
      position: relative;
    }

    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: gray;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  }  