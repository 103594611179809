@import url('https://fonts.googleapis.com/css?family=Rubik');

.about_content {
    /* display: flex; */
    font-family: "Rubik";
    position: absolute;
    left: 4%;
    z-index: 100;
}

#left {
    float: left;
    width: 25%;
    font-size: 90%;
}
  
#right {
    float: right;
    width: 75%;
  }

  #profile_pic {
    width: 180px;
    height: 200px;
    border: 1px solid black;
    outline: 2px solid white;
    margin-bottom: 20px;
  }

  .sub_header {
    color: #707070;
    font-weight: 590;
    font-size: 1.1em;
  }
  
  p.content {
    width: 85%;
  }

  @media screen and (max-width: 960px) {
      #left, #right {
          float: none;
          position: relative;
      }

      .about_content {
          position: relative;
          left: 10%;
      }

      .skill_summary {
          display: none;
      }
  }