@import url(https://fonts.googleapis.com/css?family=Rubik);
@import url(https://fonts.googleapis.com/css?family=Holtwood+One+SC);
@import url(https://fonts.googleapis.com/css?family=Marck+Script);
@import url(https://fonts.googleapis.com/css?family=Rubik+Mono+One);
@import url(https://fonts.googleapis.com/css?family=Notable);
@import url(https://fonts.googleapis.com/css?family=Rubik);
html, body {
  padding: 0;
  margin: 0;
}

body {
  background-color: #8C92AC;
  height: 100vh;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skill-content {
    font-family: "Rubik";
    width: 90%;
    font-size: 80%;
}
.about_content {
    /* display: flex; */
    font-family: "Rubik";
    position: absolute;
    left: 4%;
    z-index: 100;
}

#left {
    float: left;
    width: 25%;
    font-size: 90%;
}
  
#right {
    float: right;
    width: 75%;
  }

  #profile_pic {
    width: 180px;
    height: 200px;
    border: 1px solid black;
    outline: 2px solid white;
    margin-bottom: 20px;
  }

  .sub_header {
    color: #707070;
    font-weight: 590;
    font-size: 1.1em;
  }
  
  p.content {
    width: 85%;
  }

  @media screen and (max-width: 960px) {
      #left, #right {
          float: none;
          position: relative;
      }

      .about_content {
          position: relative;
          left: 10%;
      }

      .skill_summary {
          display: none;
      }
  }
.main_frame {
  max-width: 1300px;
  margin: auto;
  position: relative;
  top: 20px;
  background-color: #C4C3D0 ;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 93vh;
  width: 95%;
}

.container:before,
.container:after {
    content: "";
    display: table;
}

.container:after {
    clear: both;
}

.content_containaer {
  padding: 50px 37px;
  position: relative;
}

.top_title {
  font-family: 'Marck Script';
  margin-bottom: 0;
  margin-top: 0;
  color: #707070;
  padding-bottom: 0;
}

.bottom_title {
  font-family: 'Holtwood One SC';
  margin-top: 0;
  padding-top: 0;
}

@media screen and (max-width: 960px) {
 .main_frame {
   width: 90vw;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
 }
}
.navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    margin-bottom: 20px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
        position: absolute;
        width: 99px;
        height: 75px;
        background: #676767;
        font-family: 'Holtwood One SC';
        text-decoration: none;
  }

  .navbar-logo p {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #EEEEEE;
  }
  
  .nav-menu {
    width: 55%;
    float: right;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-links:hover {
    color: gray;
  }
  
  .nav-links {
    font-family: "Notable";
    list-style-type: none;
    display: inline;
    text-decoration: underline;
    font-size: 12px;
    color: black;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .navbar {
      position: relative;
    }

    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: gray;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  }  
.footer_container {
    font-family: 'Rubik';
    position: absolute;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    background-color: #676767;
    color: #f0efed;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-left: 5%;
}

.footer_copyrights {
    margin-left: 5%;
    display: inline;
}

.footer-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    margin-right: 5%;
    float: right;
}

.footer-links li {
    margin: 0;
    padding: 2px;
    cursor: pointer;
}

a.social-media-icon:link, a.social-media-icon:visited {
    color: #f0efed;
}    

@media screen and (max-width: 960px) {
    .footer_container {
        position: relative;
        margin-top: 5px;
    }
}
