@import url('https://fonts.googleapis.com/css?family=Holtwood+One+SC');
@import url('https://fonts.googleapis.com/css?family=Marck+Script');
@import url('https://fonts.googleapis.com/css?family=Rubik+Mono+One');


.main_frame {
  max-width: 1300px;
  margin: auto;
  position: relative;
  top: 20px;
  background-color: #C4C3D0 ;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: fit-content;
  min-height: 93vh;
  width: 95%;
}

.container:before,
.container:after {
    content: "";
    display: table;
}

.container:after {
    clear: both;
}

.content_containaer {
  padding: 50px 37px;
  position: relative;
}

.top_title {
  font-family: 'Marck Script';
  margin-bottom: 0;
  margin-top: 0;
  color: #707070;
  padding-bottom: 0;
}

.bottom_title {
  font-family: 'Holtwood One SC';
  margin-top: 0;
  padding-top: 0;
}

@media screen and (max-width: 960px) {
 .main_frame {
   width: 90vw;
   height: fit-content;
 }
}